<template>
  <div class="sonfig-container">
    <div class="sonfig_right">
      <div class="contract_left_search">
        <a-button type="primary" style="height: 36px" @click="handleClickAdd(1)">创建合同</a-button>
        <div>
          <span class="sonfig_date">选中日期</span>
          <a-range-picker
            style="width: 220px"
            @change="onChange"
            v-model="dateString"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :disabledDate="disabledDate"
            :allowClear="true"
            show-time
            format="YYYY-MM-DD"
          >
          </a-range-picker>
          <a-select
            @change="changeTradeId"
            v-model="contractSts"
            :allowClear="true"
            class="sonfig_industry"
            placeholder="请选择合同状态"
            style="width: 180px"
          >
            <a-select-option v-for="item in contractStsPush" :key="item.table">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-input
            v-model.trim="contractNo"
            autocomplete="off"
            style="width: 180px"
            class="sonfig_industry"
            placeholder="请输入合同编号"
            allowClear
          />
          <a-select
            @change="handleProvinceChange"
            v-model="contractType"
            style="width: 180px"
            :allowClear="true"
            class="sonfig_industry"
            placeholder="请选择合同类型"
          >
            <a-select-option v-for="item in contractTypePush" :key="item.table">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-button type="primary" style="margin-left: 10px; height: 36px" @click="handleSearch">搜索</a-button>
        </div>
      </div>
      <div class="contract_left_table">
        <a-table
          class="m_table_cust"
          :loading="loading"
          :pagination="false"
          :columns="columns"
          :data-source="dataList"
          :rowKey="
            (record, index) => {
              return index
            }
          "
          :locale="tablenodata"
          bordered
        >
          <div slot="accounts">直客</div>
          <template slot="action" slot-scope="text, record">
            <a-button size="small" @click="handleClickUpdatePlace(record)">修改</a-button>
          </template>
        </a-table>
        <m-empty style="min-height: 70vh" v-if="dataList.length === 0">
          <template #description> 暂无数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          :show-total="(total) => `共 ${total} 条`"
          show-size-changer
          :current="query.page"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <PlacesModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="this.advertisers"
      @changePlace="changePlace"
    />
  </div>
</template>

<script>
import { contractListPage } from '@/api/financialManagementA'
import PlacesModal from '../components/placesModal'
import Views from '../components/view'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
const columns = [
  {
    dataIndex: 'addTime',
    title: '日期',
    key: 'addTime',
    align: 'left'
  },
  {
    dataIndex: 'contractNo',
    title: '合同编号',
    key: 'contractNo',
    align: 'left',
    scopedSlots: { customRender: 'contractNo' }
  },
  {
    dataIndex: 'contractSts',
    title: '合同状态',
    key: 'contractSts',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.contractSts === '0') {
        text = '未归档'
      } else if (record.contractSts === '1') {
        text = '归档'
      }
      return text
    }
  },
  {
    dataIndex: 'deadline',
    title: '合同期限',
    key: 'deadline',
    align: 'left',
    customRender: (text, record, index) => {
      text = record.contractStartTime + '---' + record.contractEndTime
      return text
    }
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    align: 'left'
  },
  {
    dataIndex: 'companyName',
    title: '甲方公司',
    key: 'companyName',
    align: 'left'
  },
  {
    dataIndex: 'contractSecondParty',
    title: '乙方公司',
    key: 'contractSecondParty',
    align: 'left'
  },
  {
    dataIndex: 'incomeType',
    title: '收入模式',
    key: 'incomeType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.incomeType === '1') {
        text = '直客收入'
      } else if (record.incomeType === '2') {
        text = '平台收入'
      }
      return text
    }
  },
  {
    dataIndex: 'collectionType',
    title: '收款方式',
    key: 'collectionType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.collectionType === '1') {
        text = '预收'
      }
      return text
    }
  },

  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
export default {
  name: 'Contract',
  mixins: [mixDate],
  components: { PlacesModal, Views },
  data () {
    return {
      contractType: undefined,
      contractSts: undefined,
      contractStsPush: [
        {
          value: '未归档',
          table: 0
        },
        {
          value: '归档',
          table: 1
        }
      ],
      contractTypePush: [
        {
          value: '媒体和代理',
          table: 1
        },
        {
          value: '媒体和广告主',
          table: 2
        }
      ],
      contractNo: '',
      advertisers: '',
      columns,
      addPlaceVisible: false,
      placeInfo: {},
      supplier: 1,
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      mode: 'inline',
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      keys: '',
      types: '',
      tablenodata: {
        emptyText: () => (
          <m-empty>
            <div slot="description">暂无数据！</div>
          </m-empty>
        )
      }
    }
  },
  computed: {},
  mounted () {
    this.getContractListPage()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    onChange (date, dateString) {
      this.dateStrings = dateString
      this.times = this.dateStrings
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    handleClick (e) {
      this.keys = e.key
      this.getContractListPage()
    },
    changeMode (checked) {
      this.mode = checked ? 'vertical' : 'inline'
    },
    changeTheme (checked) {
      this.theme = checked ? 'dark' : 'light'
    },

    async getContractListPage () {
      this.loading = true
      this.dataList = []
      const res = await contractListPage(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    // 新增广告主
    handleClickAdd (type) {
      this.advertisers = 1
      this.addPlaceVisible = true
    },
    handleSearch () {
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        contractSts: this.contractSts,
        contractNo: this.contractNo || '',
        contractType: this.contractType || ''
      }
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    },
    changeTradeId (val) {},
    changeBusiness (val) {},
    handleProvinceChange (value) {
      this.manager = value
      this.businessUserPush = this.manager
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        advertiserIndustry: this.advertiserIndustry || '',
        businessUser: this.businessUserPush || ''
      }
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.advertisers = 2
      this.placeInfo = record
      this.addPlaceVisible = true
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getContractListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less">
.ant-table table {
  table-layout: fixed;
}
.sonfig-container {
  padding: 0 10px;
  .ant-menu-item-selected {
    background-color: fade(@primary-color,  5%);
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      border-radius: 5px;
      border-left: 3px solid @primary-color;
      border-right: none;
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .sonfig_date {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sonfig_industry {
    margin-left: 10px;
    width: 120px;
  }
  .ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 5px;
  }
  .sonfig_right {
    flex-grow: 1;
    border-radius: 5px;
    .contract_left_search {
      padding: 20px;
      border-radius: 5px 0;
      background: #fff;
      display: flex;
      justify-content: space-between;
    }
    .contract_left_table {
      border-radius: 0 5px;
      background: #fff;
      padding: 0 20px 20px;
      height: 100%;
      min-height: 85vh;
      .pagination {
        padding-top: 20px;
      }
    }
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
  }
  .ant-table-placeholder {
    border-bottom: none;
  }
}
</style>
