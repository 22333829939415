var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sonfig-container"},[_c('div',{staticClass:"sonfig_right"},[_c('div',{staticClass:"contract_left_search"},[_c('a-button',{staticStyle:{"height":"36px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleClickAdd(1)}}},[_vm._v("创建合同")]),_c('div',[_c('span',{staticClass:"sonfig_date"},[_vm._v("选中日期")]),_c('a-range-picker',{staticStyle:{"width":"220px"},attrs:{"ranges":{
            今日: [_vm.initDate(0, 0).start, _vm.initDate(0, 0).end],
            昨日: [_vm.initDate(1, 1).start, _vm.initDate(1, 1).end],
            最近一周: [_vm.initDate(6, 0).start, _vm.initDate(6, 0).end],
            最近一个月: [_vm.initDate(30, 0).start, _vm.initDate(30, 0).end]
          },"disabledDate":_vm.disabledDate,"allowClear":true,"show-time":"","format":"YYYY-MM-DD"},on:{"change":_vm.onChange},model:{value:(_vm.dateString),callback:function ($$v) {_vm.dateString=$$v},expression:"dateString"}}),_c('a-select',{staticClass:"sonfig_industry",staticStyle:{"width":"180px"},attrs:{"allowClear":true,"placeholder":"请选择合同状态"},on:{"change":_vm.changeTradeId},model:{value:(_vm.contractSts),callback:function ($$v) {_vm.contractSts=$$v},expression:"contractSts"}},_vm._l((_vm.contractStsPush),function(item){return _c('a-select-option',{key:item.table},[_vm._v(" "+_vm._s(item.value)+" ")])}),1),_c('a-input',{staticClass:"sonfig_industry",staticStyle:{"width":"180px"},attrs:{"autocomplete":"off","placeholder":"请输入合同编号","allowClear":""},model:{value:(_vm.contractNo),callback:function ($$v) {_vm.contractNo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contractNo"}}),_c('a-select',{staticClass:"sonfig_industry",staticStyle:{"width":"180px"},attrs:{"allowClear":true,"placeholder":"请选择合同类型"},on:{"change":_vm.handleProvinceChange},model:{value:(_vm.contractType),callback:function ($$v) {_vm.contractType=$$v},expression:"contractType"}},_vm._l((_vm.contractTypePush),function(item){return _c('a-select-option',{key:item.table},[_vm._v(" "+_vm._s(item.value)+" ")])}),1),_c('a-button',{staticStyle:{"margin-left":"10px","height":"36px"},attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("搜索")])],1)],1),_c('div',{staticClass:"contract_left_table"},[_c('a-table',{staticClass:"m_table_cust",attrs:{"loading":_vm.loading,"pagination":false,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":(record, index) => {
            return index
          },"locale":_vm.tablenodata,"bordered":""},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleClickUpdatePlace(record)}}},[_vm._v("修改")])]}}])},[_c('div',{attrs:{"slot":"accounts"},slot:"accounts"},[_vm._v("直客")])]),(_vm.dataList.length === 0)?_c('m-empty',{staticStyle:{"min-height":"70vh"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" 暂无数据 ")]},proxy:true}],null,false,2090169575)}):_vm._e(),_c('a-pagination',{staticClass:"pagination",attrs:{"show-total":(total) => `共 ${total} 条`,"show-size-changer":"","current":_vm.query.page,"total":_vm.total},on:{"change":_vm.handleChangePage,"showSizeChange":_vm.onShowSizeChange}})],1)]),(_vm.addPlaceVisible)?_c('PlacesModal',{attrs:{"placeInfo":_vm.placeInfo,"visible":_vm.addPlaceVisible,"isAdd":this.advertisers},on:{"modalCancel":function($event){_vm.addPlaceVisible = false},"changePlace":_vm.changePlace}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }